/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface Store {
  id?: string;
  email?: string;
  role?: string;
  settings?: { [key: string]: any };
  token?: string;
  auth: boolean;
  set: (
    id: string,
    email: string,
    role: string,
    settings: { [key: string]: any },
    token: string
  ) => void;
  clear: () => void;
}

const useStore = create<Store>()(
  persist(
    (set) => ({
      auth: false,
      set: (
        id: string,
        email: string,
        role: string,
        settings: { [key: string]: any },
        token: string
      ) => {
        localStorage.setItem('token', token);
        set(() => ({
          id: id,
          email: email,
          role: role,
          settings: settings,
          token: token,
          auth: true,
        }));
      },
      clear: () => {
        localStorage.setItem('token', '');
        set(() => ({
          id: undefined,
          email: undefined,
          token: undefined,
          auth: false,
        }));
      },
    }),
    {
      name: 'auth-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
